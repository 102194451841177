        
<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<button class=" m-r-20 hidden-sm-up" mat-raised-button color="warn" matTooltip="Non ancora disponibile" matTooltipPosition="above"
 (click)="openNotAvailableSnackBar('Non ancora disponibile','X')">  Iscriviti </button>

<!-- <a href="http_//meteoturate.it"  mat-raised-button color="warn"></a> -->
<button  mat-icon-button class="m-r-5"  (click)="openNotAvailableSnackBar('Non ancora disponibile', 'X')"> 
    <img src="assets/icon/noUser.jpg" alt="user" class="profile-pic"  matTooltip="Non ancora disponibile" matTooltipPosition="above">
</button>


<!-- <button [matMenuTriggerFor]="profile"   mat-icon-button class="m-r-5" (click)="openNotAvailableSnackBar('Non ancora disponibile', 'X')"> 
    <img src="assets/icon/noUser.jpg" alt="user" class="profile-pic">
</button> -->

<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button> 
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button>
    <button mat-menu-item>
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>
</mat-menu>

