// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  weatherapiKey: "a800bf61abdc454795984028222605",
  openweathermapKey: "03424424d7f3e2caa885fd1db949cf3d",
};
